import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomeModule)
  },
  {
    // path: 'interna',
    path: 'interna/:category/:subcategory',
    loadChildren: () => import('./interna/interna.module').then( m => m.InternaModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then( m => m.ContatoModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsModule)
  },
  {
    path: 'pinturas',
    loadChildren: () => import('./pinturas/pinturas.module').then( m => m.PinturasModule)
  },
  {
    path: 'espaco',
    loadChildren: () => import('./espaco/espaco.module').then( m => m.EspacoModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
